import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Page from '../templates/page'

export const query = graphql`
  query page404($lang: String!) {
    prismicPage(uid: { eq: "404" }, lang: { eq: $lang }) {
      ...PrismicPageFragment
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
  }
`


const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  const navigation = data.prismicNavigation
  return (

    <Layout navigation={navigation}>
      <SEO title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
        return (
          <SliceList slice={slice} key={`slice-${index}`} />
        )
      })}
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: Page,
  },
})
